/**
 * Возвращает ссылку на хелпцентер с допустимой локалью
 * и email поддержки
 */

interface SupportUrlReturn {
  supportUrl: ComputedRef<string>
  supportEmail: string
  supportEmailLink: string
}

export function useSupportUrl(): SupportUrlReturn {
  const availableLocales = ['ru', 'en']
  const { locale } = useI18n()
  const supportCenter = 'https://help.betone.com/'

  const supportUrl = computed(() =>
    availableLocales.includes(locale.value)
      ? `${supportCenter}${locale.value}`
      : `${supportCenter}en`,
  )

  const supportEmail = 'support@betone.com'
  const supportEmailLink = `mailto:${supportEmail}`

  return { supportUrl, supportEmail, supportEmailLink }
}
